import axios from 'axios';

import httpClient from '@/api/httpClient';

import LocalStorageService from '@/services/LocalStorageService';

import {
  LOGISTIC_CENTER_ENDPOINT,
  COUNTRY_ENDPOINT,
  PATIENT_INVOICES_ENDPOINT
} from '@/constants/endpoints';

const createLogisticCenter = params =>
  httpClient.post(`${LOGISTIC_CENTER_ENDPOINT}/create`, params);

const updateLogisticCenter = (logisticCenterId, params) =>
  httpClient.patch(`${LOGISTIC_CENTER_ENDPOINT}/${logisticCenterId}/update`, params);



  /**
   * @param {string } id
   * @returns { Promise<{id: number¨}> }
    */
const getLogisticCenterById = id => httpClient.get(`${LOGISTIC_CENTER_ENDPOINT}/${id}`);
const getAllLogisticCenters = () => httpClient.get(`${LOGISTIC_CENTER_ENDPOINT}/all`);
const getLogisticCenterDeliveryCostsById = id =>
  httpClient.get(`${LOGISTIC_CENTER_ENDPOINT}/${id}/prices`);

const getContactsListByLogisticCenterId = logisticCenterId =>
  httpClient.get(`${LOGISTIC_CENTER_ENDPOINT}/contacts/${logisticCenterId}`);

const createLogisticCenterContact = (logisticCenterId, params) =>
  httpClient.post(`${LOGISTIC_CENTER_ENDPOINT}/${logisticCenterId}/contacts/create`, params);

const updateLogisticCenterContact = (logisticCenterId, params) =>
  httpClient.patch(`${LOGISTIC_CENTER_ENDPOINT}/${logisticCenterId}/contacts/update`, params);

const deleteLogisticCenterContact = ({ logisticCenterId, contactId }) =>
  httpClient.delete(`${LOGISTIC_CENTER_ENDPOINT}/${logisticCenterId}/contacts/${contactId}`);

const getAllCountries = () => httpClient.get(`${COUNTRY_ENDPOINT}/all`);
const getCountryById = id => httpClient.get(`${COUNTRY_ENDPOINT}/${id}`);

const getLogisticCenterProducts = id =>
  httpClient.post(`${LOGISTIC_CENTER_ENDPOINT}/${id}/products`);

const getLogisticCenterOrders = (logisticCenterId, params) =>
  httpClient.post(`${LOGISTIC_CENTER_ENDPOINT}/${logisticCenterId}/orders/findPaged`, params);

const updateLogisticCenterProduct = product =>
  httpClient.post(`${LOGISTIC_CENTER_ENDPOINT}/products/update`, product);

const uploadLogisticCenterStock = ({ id, file }) => {
  const requestUrl = `${LOGISTIC_CENTER_ENDPOINT}/${id}/stock/update`;
  const formData = new FormData();
  const fileName = `stock${id}`;

  formData.append('file', file, fileName);

  return httpClient.post(requestUrl, formData, { 'content-Type': 'multipart/form-data' });
};

const updateOrderTrackingNumber = (orderId, trackingNumber) =>
  httpClient.post(
    `${LOGISTIC_CENTER_ENDPOINT}/orders/${orderId}/trackingNumber/set`,
    trackingNumber
  );

const uploadTrackingNumbers = file => {
  const requestUrl = `${LOGISTIC_CENTER_ENDPOINT}/trackingNumbers/set`;
  const formData = new FormData();
  const fileName = `trackingNumbers`;

  formData.append('file', file, fileName);

  return httpClient.post(requestUrl, formData, { 'content-Type': 'multipart/form-data' });
};

const uploadInvoices = files => {
  const requestUrl = `${PATIENT_INVOICES_ENDPOINT}/patient/upload`;
  const formData = new FormData();

  Array.from(files).forEach(file => formData.append('invoicePdf', file, file.name));

  return httpClient.post(requestUrl, formData, { 'content-Type': 'multipart/form-data' });
};

const downloadOrderInvoice = orderId => {
  const token = LocalStorageService.getToken();

  return axios({
    baseURL: process.env.VUE_APP_BASE_URL,
    url: `${PATIENT_INVOICES_ENDPOINT}/patient/${orderId}/find`,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: { orderId }
  });
};

const getCarriers = () => httpClient.get(`${LOGISTIC_CENTER_ENDPOINT}/carriers`);

export {
  createLogisticCenter,
  updateLogisticCenter,
  getLogisticCenterById,
  getAllLogisticCenters,
  getLogisticCenterDeliveryCostsById,
  getContactsListByLogisticCenterId,
  createLogisticCenterContact,
  updateLogisticCenterContact,
  deleteLogisticCenterContact,
  getAllCountries,
  getCountryById,
  getLogisticCenterProducts,
  getLogisticCenterOrders,
  updateLogisticCenterProduct,
  uploadLogisticCenterStock,
  updateOrderTrackingNumber,
  uploadTrackingNumbers,
  uploadInvoices,
  downloadOrderInvoice,
  getCarriers
};
