<template>
  <update-info-modal
    v-model="isLogisticCenterModalShown"
    width="420"
    scrollable
    action-button-label="Save"
    :loading="isLoading"
    :modal-title="logisticCenterModalTitle"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
      <v-form ref="logisticCenterInfoForm" :key="isLogisticCenterModalShown" class="pt-1">
        <base-text-field v-model="logisticCenterInfo.name" label="Name *" :rules="nameFieldRules" />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { pick } from 'ramda';

import BaseTextField from '@/components/common/BaseTextField.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { createLogisticCenter, updateLogisticCenter } from '@/api/logisticCenter.api';

import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR, VALIDATION_RULES } from '@/constants/common';

const LOGISTIC_CENTER_COPY_FIELD_NAMES = ['id', 'name'];

export default {
  name: 'UpdateLogisticCenterInfoModal',
  components: { BaseTextField, UpdateInfoModal },
  props: {
    logisticCenter: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logisticCenterInfo: {},
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      isLogisticCenterModalShown: false,
      nameFieldRules: [VALIDATION_RULES.REQUIRED],
      isLoading: false
    };
  },
  computed: {
    logisticCenterModalTitle() {
      const titlePrefix = this.editMode ? 'Edit' : 'New';
      return `${titlePrefix} Logistic Center`;
    },
  },
  mounted() {
    if (!this.editMode) {
      return;
    }

    this.getInfoFromLogisticCenter();
  },
  methods: {
    onModalClose() {
      this.isLogisticCenterModalShown = false;

      if (this.editMode) {
        this.getInfoFromLogisticCenter();

        return;
      }

      this.logisticCenterInfo = {};
    },
    getInfoFromLogisticCenter() {
      this.logisticCenterInfo = pick(LOGISTIC_CENTER_COPY_FIELD_NAMES, this.logisticCenter);
    },
    async onActionButtonClick() {
      if (!this.$refs.logisticCenterInfoForm.validate()) {
        return;
      }

      this.isLoading = true;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      try {
        if (this.editMode) {
          await updateLogisticCenter({ ...this.logisticCenter, ...this.logisticCenterInfo });
          this.onModalClose();
          this.$emit('update-logistic-center');

          return;
        }

        await createLogisticCenter({ ...this.logisticCenterInfo, regions: [] });

        this.$emit('create-logistic-center');
        this.onModalClose();
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
