<template>
  <main class="d-flex flex-column fill-height white">
    <table-page-main-header title="Logistic centers">
      <template v-slot:header-actions>
        <update-logistic-center-info-modal
          @update-logistic-center="updateLogisticCentersList"
          @create-logistic-center="updateLogisticCentersList"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              tile
              large
              color="light-blue darken-4"
              class="white--text"
              min-width="166"
              v-on="on"
            >
              New LC
            </v-btn>
          </template>
        </update-logistic-center-info-modal>
      </template>
    </table-page-main-header>
    <section class="flex-grow-1">
      <data-table :headers="headers" :items="logisticCenters" :loading="isLoading">
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <user-avatar :photo="item.photo" />
            <router-link
              class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
              :to="{ name: 'LogisticCenterDetails', params: { id: item.id } }"
            >
              {{ item.name }}
            </router-link>
          </div>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import TablePageMainHeader from '@/components/common/TablePageMainHeader.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import UpdateLogisticCenterInfoModal from '@/components/logistic-center/UpdateLogisticCenterInfoModal.vue';

import { getAllLogisticCenters } from '@/api/logisticCenter.api';

const LOGISTIC_CENTERS_TABLE_CONFIG = [
  { text: 'Name', value: 'name', width: '350' },
  { text: 'Country', value: 'logisticCenterCountries' }
];

export default {
  name: 'LogisticCentersPage',
  components: { UpdateLogisticCenterInfoModal, UserAvatar, DataTable, TablePageMainHeader },
  data() {
    return {
      logisticCenters: [],
      headers: LOGISTIC_CENTERS_TABLE_CONFIG,
      isLoading: false
    };
  },
  mounted() {
    this.updateLogisticCentersList();
  },
  methods: {
    async updateLogisticCentersList() {
      this.logisticCenters = await this.getLogisticCenters();
    },
    async getLogisticCenters() {
      this.isLoading = true;
      const { data } = await getAllLogisticCenters();
      this.isLoading = false;

      return this.transformLogisticCentersData(data);
    },
    transformLogisticCentersData(logisticCenters = []) {
      return logisticCenters.map(({ id, name, photo = '', regions = [] }) => {
        const logisticCenterCountries = regions
          .map(({ name: regionName, countryCode }) => `${regionName} (${countryCode})`)
          .join(', ');

        return { id, name, photo, logisticCenterCountries };
      });
    }
  }
};
</script>
